import debounce from 'lodash/debounce';
import { utils } from './_utils';

const bodyPaddingTop = {
    init() {
        let self = this;       

        if (!document.getElementsByTagName('body')[0].classList.contains('home') &&
            !document.getElementsByTagName('body')[0].classList.contains('single-post')) {
            
            setTimeout(function(){
                self.setPadding();
            }, 100);
    
            $(window).on('resize', debounce(function () {
                self.setPadding();
            }, 100));
        }
    },

    getPaddingTop: function() {
        return utils.headerHeight() - this.getAdminBarHeight();
    },

    setPadding : function() {
        document.body.style.paddingTop = this.getPaddingTop() + 'px';
    },

    resetPadding : function() {
        document.body.style.paddingTop = 0;
    },

    getAdminBarHeight : function() {
        if($('body').hasClass('logged-in') && $('#wpadminbar').length > 1){
            return $('#wpadminbar').outerHeight();
        }

        return 0;
    }
}

export {bodyPaddingTop};