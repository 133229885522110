import { Accordion } from 'foundation-sites/js/foundation.accordion';
Foundation.plugin(Accordion, 'Accordion');

const crechesPage = {
    readyFn: function () {
        let self = this;
        if ($('#faq .accordion-item').length) {
            var elem = new Foundation.Accordion($('#faq .accordion'));
        }
        

        $('#search-results [name="register-creche"]').on('change', function (e) {
            let $parent = $(this).parent();

            if ($('#search-results [name="register-creche"]:checked').length > 3) {
                alert('Vous ne pouvez sélectionner que 3 crèches maximum');

                if ($(this).is(':checked')) {
                    $(this).prop("checked", false);
                }

            } else {
                
                if ($(this).is(':checked')) {
                    $parent.find('.add-to-registration').hide();
                    $parent.find('.delete-from-registration').show();
                } else {
                    $parent.find('.add-to-registration').show();
                    $parent.find('.delete-from-registration').hide();
                }
    
                self.updateFloatBar();
            }

        });

        $('#registration-banner .selected-creches').on('click', 'li', function () {
            let crecheName = $(this).data('creche');

            console.log($('#' + crecheName));

            $('#' + crecheName).prop("checked", false).trigger('change');
        });
    },

    updateFloatBar: function () {
        let creches = '';
        if ($('#search-results [name="register-creche"]:checked').length > 0) {
            $('#registration-banner').show();
            $('#registration-banner .selected-creches li').remove();

            let index = 1;
            $('[name="register-creche"]:checked').each(function () {
                let crecheId = $(this).attr('id');
                let crecheName = $(this).parents('.item-content').find('h3').text();
                let thisCreche = index > 1 ? ';' + crecheId : crecheId;

                $('#registration-banner .selected-creches').append('<li data-creche="' + crecheId + '">' + crecheName + '</li>');
                
                creches = creches.concat(thisCreche);

                index++;
            });

        } else {
            creches = '';
            $('#registration-banner').hide();
        }

        $('#registration-banner input[name="creches"]').val(creches);
    },
}

export { crechesPage };