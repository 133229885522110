import debounce from "lodash/debounce";
import Swiper, {Navigation,Pagination,Controller,EffectFade} from "swiper";
Swiper.use([Navigation, Pagination, Controller, EffectFade]);
import { Tabs } from "foundation-sites/js/foundation.tabs";
Foundation.plugin(Tabs, "Tabs");

import { utils } from "../components/_utils";

const crechePage = {
	topPageContent: 0,
	bottomPageContent: 0,
	curScrollTop: 0,
	exceedValue: 0,
	deviceKind: utils.getDeviceKind(),
	deviceHeight: utils.heightDevice(),
	sidebar: document.getElementById("sidebar"),

	readyFn: function () {
		let self = this;

		// Slider intro
		self.setGallerySlider();
		self.setTeamSlider();
		self.setProximitySlider();

		if ($(".tabs").length) {
			var tabs = new Foundation.Tabs($(".tabs"));
		}

		jQuery(window).on(
			"resize",
			debounce(function () {
				self.deviceKind = utils.getDeviceKind();
				if (self.deviceKind == "isDesktop") {
					self.topPageContent = self.getTopPageContent();
					self.bottomPageContent = self.getBottomPageContent();
					self.setSidebarPos();
					self.setSidebarWidth();
				} else {
					self.resetSidebar();
				}
			}, 100)
		);
	},

	loadFn: function () {
		let self = this;

		self.topPageContent = self.getTopPageContent();
		self.bottomPageContent = self.getBottomPageContent();
		self.exceedValue = self.bottomPageContent - self.deviceHeight;

		if (self.deviceKind == "isDesktop") {
			self.setSidebarWidth();

			$(window).on("scroll", () => {
				if (self.deviceKind == "isDesktop") {
					self.setSidebarPos();
				} else {
					self.resetSidebar();
				}
			});
		}
	},

	setGallerySlider: function () {
		if (
			$(".gallery-slider .swiper-slide").length > 1 &&
			self.deviceKind !== "isMobile"
		) {
			let prevBtn = document.getElementsByClassName("gallery-slider")[0].querySelector(".swiper-prev");
			let nextBtn = document.getElementsByClassName("gallery-slider")[0].querySelector(".swiper-next");

			const gallerySlider = new Swiper(".gallery-slider", {
				spaceBetween: 0,
				slidesPerView: 1,
				breakpoints: {
					768: {
						slidesPerView: 2,
						spaceBetween: 30,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 40,
					},
				},
				pagination: {
					el: $(".gallery-slider .swiper-pagination")[0],
					type: "bullets",
					clickable: true,
				},
				fadeEffect: {
					crossFade: true,
				},
				navigation: {
					prevEl: prevBtn,
					nextEl: nextBtn,
				},
			});
		}
	},

	setTeamSlider: function () {
		if ($(".team-slider .swiper-slide").length > 1) {
			let prevBtn = document
				.getElementsByClassName("team-slider")[0]
				.querySelector(".swiper-prev");
			let nextBtn = document
				.getElementsByClassName("team-slider")[0]
				.querySelector(".swiper-next");

			const teamSlider = new Swiper(".team-slider", {
				spaceBetween: 40,
				slidesPerView: 2,
				navigation: {
					prevEl: prevBtn,
					nextEl: nextBtn,
				},
				breakpoints: {
					480: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
					1024: {
						slidesPerView: 4,
						spaceBetween: 40,
					},
				},
			});
		}
	},

	setProximitySlider: function () {
		if ($(".proximity-slider .swiper-slide").length > 1) {
			let prevBtn = document
				.getElementsByClassName("proximity-slider")[0]
				.querySelector(".swiper-prev");
			let nextBtn = document
				.getElementsByClassName("proximity-slider")[0]
				.querySelector(".swiper-next");

			const proximitySlider = new Swiper(".proximity-slider", {
				spaceBetween: 20,
				slidesPerView: "auto",
				navigation: {
					prevEl: prevBtn,
					nextEl: nextBtn,
				},
			});
		}
	},

	getTopPageContent: function () {
		return $("#our-creche").offset().top;
	},

	getBottomPageContent: function () {
		let $lastSection = $("#main-content section").last();
		let lastContentTop = $lastSection.offset().top;
		let lastContentHeight = $lastSection.outerHeight();

		return lastContentTop + lastContentHeight;
	},

	setSidebarPos: function () {
		let self = this;
		self.curScrollTop = $(window).scrollTop();

		// si le scrollTop dépasse le Top de la premiere liste d'items
		if (self.curScrollTop >= self.topPageContent - utils.headerHeight()) {
			self.sidebar.classList.add("fixed");
			self.setSidebarLeft();
		} else {
			if (self.sidebar.classList.contains("fixed")) {
				self.sidebar.classList.remove("fixed");
			}
			self.sidebar.style.left = "auto";
		}

		// si le scrollTop dépasse le bas de la dernière liste d'items
		if (self.curScrollTop >= self.exceedValue) {
			let translate = self.exceedValue - self.curScrollTop;
			self.sidebar.style.transform = "translateY(" + translate + "px)";
		}
	},

	setSidebarWidth: function () {
		let self = this;
		let widgetParent = self.sidebar.parentNode;
		let computedStyle = getComputedStyle(widgetParent);
		self.sidebar.style.width =
			widgetParent.clientWidth -
			(parseFloat(computedStyle.paddingLeft) +
				parseFloat(computedStyle.paddingRight)) +
			"px";
	},

	setSidebarLeft: function () {
		let self = this;
		let widgetParent = self.sidebar.parentNode;
		let computedStyle = getComputedStyle(widgetParent);
		self.sidebar.style.left =
			widgetParent.getBoundingClientRect().left +
			parseFloat(computedStyle.paddingLeft) +
			"px";
	},

	resetSidebar: function () {
		let self = this;

		self.sidebar.removeAttribute("style");
		self.sidebar.classList.remove("fixed");
	},
};

export { crechePage };
