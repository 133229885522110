import debounce from 'lodash/debounce';
import { utils } from './_utils';

const footerWave = {
    init : function () {
        let self = this;

        self.waveHeight();

        jQuery(window).on('resize', debounce(function () {
            self.waveHeight();
		}, 50));
    },

    // Permet de ne pas avoir de hauteur de l'élément sur un demi-pixel et donc une ligne blanche en bas de celui-ci
    waveHeight: function () {
        let ratio = 15.118; //Ratio hauteur/largeur du svg bg-footer.svg
        $('.footer-wave').css('height', Math.round(utils.widthDevice() / ratio) + 'px');
    }
}

export { footerWave };