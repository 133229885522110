import debounce from 'lodash/debounce';
import { utils } from '../components/_utils';
import { bodyPaddingTop } from '../components/_bodyPaddingTop';

const singleJobPage = {
    topPageContent: 0,
    bottomPageContent: 0,
    curScrollTop: 0,
    exceedValue: 0,
    deviceKind: utils.getDeviceKind(),
    deviceHeight: utils.heightDevice(),
    sidebar: document.getElementById('sidebar'),

    readyFn: function () {
        let self = this;

        jQuery(window).on('resize', debounce(function () {
            self.deviceKind = utils.getDeviceKind();

            if (self.deviceKind == 'isDesktop') {                
                self.topPageContent = self.getTopPageContent();
                self.bottomPageContent = self.getBottomPageContent();
                self.setSidebarPos();
                self.setSidebarWidth();
                
            } else {
                
                self.resetSidebar();

            }
        }, 100));
    },

    loadFn : function() {
        let self = this;

        if (self.deviceKind == 'isDesktop') {
            self.topPageContent = self.getTopPageContent();
            self.bottomPageContent = self.getBottomPageContent();
            self.exceedValue = self.bottomPageContent - self.deviceHeight + sidebar.offsetHeight;

            self.setSidebarWidth();

            $(window).on('scroll', () => {

                if(self.deviceKind == 'isDesktop') {

                    self.setSidebarPos();
                    
                } else {
                    
                    self.resetSidebar();

                }
            });
        }
    },

    getTopPageContent: function () {
        return $('.job-content').offset().top + bodyPaddingTop.getPaddingTop();
    },

    getBottomPageContent: function () {
        let contentTop = this.getTopPageContent();
        let contentHeight = $('.job-content').outerHeight();
        
        return contentTop + contentHeight;
    },

    setSidebarPos : function() {
        let self = this;
        self.curScrollTop = $(window).scrollTop();

        // si le scrollTop dépasse le Top de la premiere liste d'items
        if(self.curScrollTop >= (self.topPageContent - utils.headerHeight())) {
            self.sidebar.classList.add('fixed');
            self.setSidebarLeft();
        } else {
            if(self.sidebar.classList.contains('fixed')){
                self.sidebar.classList.remove('fixed');
            }
            self.sidebar.style.left = 'auto';
        }

        // si le scrollTop dépasse le bas de la dernière liste d'items
        if(self.curScrollTop >= self.exceedValue) {
            let translate = self.exceedValue - self.curScrollTop;
            self.sidebar.style.transform = 'translateY(' + translate + 'px)';
        }        
    },

    setSidebarWidth : function() {
        let self = this;
        let widgetParent = self.sidebar.parentNode;
        let computedStyle = getComputedStyle(widgetParent);
        self.sidebar.style.width = (widgetParent.clientWidth - (parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight))) + 'px';
    },

    setSidebarLeft : function() {
        let self = this;
        let widgetParent = self.sidebar.parentNode;
        let computedStyle = getComputedStyle(widgetParent);
        self.sidebar.style.left = (widgetParent.getBoundingClientRect().left + parseFloat(computedStyle.paddingLeft)) + 'px';
    },

    resetSidebar : function() {
        let self= this;

        self.sidebar.removeAttribute('style');
        self.sidebar.classList.remove('fixed');
    }
}

export { singleJobPage };