import { bodyPaddingTop } from '../components/_bodyPaddingTop';

const singleBlogPage = {
    readyFn : function() {
        $('#intro').css('padding-top', bodyPaddingTop.getPaddingTop() + 'px');
    },
    
    loadFn : function() {
    },
}

export {singleBlogPage};