// IMPORT VENDOR
import debounce from "lodash/debounce";
import { Foundation } from "foundation-sites/js/foundation.core";
Foundation.addToJquery($);
import { MediaQuery } from "foundation-sites/js/foundation.util.mediaQuery";
Foundation.plugin(MediaQuery, "MediaQuery");
import { DropdownMenu } from "foundation-sites/js/foundation.dropdownMenu";
Foundation.plugin(DropdownMenu, "DropdownMenu");
import { AccordionMenu } from "foundation-sites/js/foundation.accordionMenu";
Foundation.plugin(AccordionMenu, "AccordionMenu");
import AOS from "aos";
import GLightbox from "glightbox";


// IMPORT DES COMPONENTS
import { utils } from "./components/_utils";
import HeaderClass from "./components/_header";
import ScrollToLink from "./components/_scrollTo";
import { footerWave } from "./components/_footerWave";
import { videoBlock } from "./components/_video";
import { inputFile } from "./components/_inputFile";
import { geolocation } from "./components/_geolocation";
import { faq } from "./components/_faq";

// IMPORT DES PAGES
import { homePage } from "./pages/home";
import { crechesPage } from "./pages/creches";
import { crechePage } from "./pages/singleCreche";
import { registerChildPage } from "./pages/registerChild";
import { singleJobPage } from "./pages/singleJob";
import { singleBlogPage } from "./pages/singleBlog";
import { calculateur } from "./pages/calculateur";

// ----------------------------------- DOCUMENT READY -----------------------------------
// --------------------------------------------------------------------------------------
jQuery(function () {
	$(".no-js").removeClass("no-js");

	utils.checkDevice();
	jQuery(window).on("resize", debounce(function () {
		utils.checkDevice();
	}, 100));

	// Init scrollTo class
	let scrollTo = new ScrollToLink();
	scrollTo.init();

	// Init Header class
	let header = new HeaderClass();
	header.initHeader();

	// Padding top de la hauteur du header sur le <body>
	//bodyPaddingTop.init();

	// Permet de ne pas avoir de hauteur de l'élément sur un demi-pixel et donc une ligne blanche en bas de celui-ci
	footerWave.init();

	// Au clic sur le thumbnail de la vidéo, masque le thumbnail et lit la vidéo
	videoBlock.init();

	// Manager de fichier uploadés dans un formulaire
	inputFile.init();

	// Popup glightbox
	const lightbox = GLightbox();

	// Filtres des crèches
	let $grid = document.getElementsByClassName("results-list")[0];
	if ($grid) {
		let crecheItems = $grid.querySelectorAll('.creche-item');
		let selectedLength = 0;
	
		$(".filters-button-group").on("click", "a", function () {
			$(this).toggleClass("hollow active");
			let filterValue = [];
			selectedLength = 0;
	
			$(".filters-button-group").find(".button").each(function () {
				if ($(this).hasClass("active")) {
					filterValue.push($(this).attr("data-filter"));
				}
			});
			
			Array.prototype.forEach.call(crecheItems, function (el) { 
				if (filterValue.every(className => el.classList.contains(className))) {
					el.style.display = 'flex';
					selectedLength++;
				} else {
					el.style.display = 'none';
				}
			});
			
			$('.nb-result .nb').text(selectedLength);
		});
	}

	geolocation.init();

	// Accordéons de la section FAQ
	faq.init();

	// Rayon de recherche
	$("#rayon-range").on("input change", function () {
		$("#rayon-number").attr("placeholder", "Rayon de " + $(this).val() + " km");
	});
	$("#rayon-number").on("input change", function () {
		$("#rayon-range").val($(this).val());
	});

	// Sur mobile affiche la liste des crèches résultat
	$(".header-list").on("click", function () {
		$(this).toggleClass("active");
		$(this).next(".results-list").slideToggle("fast");
	});

	// Execute le script de la page dans laquelle on se trouve
	let bodyClassList = document.getElementsByTagName("body")[0].classList;
	switch (true) {
		case bodyClassList.contains("home"):
			homePage.readyFn();
			break;
		case bodyClassList.contains("page-template-page-creches"):
		case bodyClassList.contains("tax-localite"):
			crechesPage.readyFn();
			break;
		case bodyClassList.contains("single-creche"):
			crechePage.readyFn();
			calculateur.calculateRate();
			break;
		case bodyClassList.contains("page-template-page-register-child"):
			registerChildPage.readyFn();
			break;
		case bodyClassList.contains("single-emploi"):
			singleJobPage.readyFn();
			break;
		case bodyClassList.contains("single-post"):
			singleBlogPage.readyFn();
			break;
		case bodyClassList.contains("page-template-page-calculateur-de-couts"):
			calculateur.chooseOne();
			calculateur.calculateRate();
			break;
	}
});

// ----------------------------------- WINDOW LOAD --------------------------------------
// --------------------------------------------------------------------------------------
$(window).on("load", function () {
	document.body.classList.add("loaded");

	// Execute le script de la page dans laquelle on se trouve
	let bodyClassList = document.getElementsByTagName("body")[0].classList;
	switch (true) {
		case bodyClassList.contains("home"):
			homePage.loadFn();
			break;
		case bodyClassList.contains("single-creche"):
			crechePage.loadFn();
			break;
		case bodyClassList.contains("single-emploi"):
			singleJobPage.loadFn();
			break;
	}

	// Google Maps : autocomplétion dans le champ de recherche
	var input = document.getElementById("localisation-search");
	if (input != null) {
		var autocomplete = new google.maps.places.Autocomplete(input);
		autocomplete.setComponentRestrictions({ country: ["fr"] });
	}
	

	// Execute AOS
	setTimeout(() => {
		AOS.init({
			once: true,
			anchorPlacement: "top-center",
			offset: -250,
		});

		$("[data-aos]").one(
			"transitionend webkitTransitionEnd oTransitionEnd",
			function () {
				$(this).addClass("aos-end");
			}
		);
	}, 100);
});
