const videoBlock = {
    init: function () {
        if ($('.video-wrapper').length > 0) {
            $('.video-wrapper').on('click', function () {
                $(this).find('.video-thumbnail').fadeOut();
                $(this).find('iframe')[0].src += "&autoplay=1";
            });
        }
    }
}

export { videoBlock };