const calculateur = {
	readyFn: function () {
		self.calculateRate();
	},

	calculateRate: function () {
		let self = this;

		$(document).on("submit", "form.calcul-multi-form", function (e) {
			e.preventDefault();
			var nb_children = $("input#nb-children").val(),
				duration = $("input#duration").val(),
				revenu = $("input#revenu").val(),
				disabled = $("input#disabled").is(":checked") ? true : false,
				te; // taux d'effort

			if (
				!nb_children ||
				nb_children === "0" ||
				!duration ||
				duration === "0" ||
				!revenu
			) {
				console.log("incomplet");
			} else {
				if (nb_children == 1 || (nb_children == 2 && disabled)) {
					te = 0.0615;
				} else if (nb_children == 2 || (nb_children == 3 && disabled)) {
					te = 0.0512;
				} else if (nb_children == 3 || (nb_children >= 4 && disabled)) {
					te = 0.041;
				} else if (nb_children >= 4 || (nb_children >= 8 && disabled)) {
					te = 0.0317;
				} else if (nb_children >= 8) {
					te = 0.0205;
				}

				if (revenu < 711.62) revenu = 711.62;
				if (revenu > 5800) revenu = 5800;

				var horaire = ((revenu * te) / 100).toFixed(3);
				$(".price-per-hour .price").html(horaire + "€");

				var hebdo = (horaire * duration).toFixed(2);
				$(".price-per-week .price").html(hebdo + "€");

				var mensuel = (hebdo * 3.76).toFixed(2); // 3.76 semaines par mois environ (basé sur la CNAF qui donne 21 jours ouvrés par mois environ)
				$(".price-per-month .price").html(mensuel + "€");
			}
		});

		$(document).on("submit", "form.calcul-ljp-form", function (e) {
			e.preventDefault();
			var nb_children = $("input#nb-children").val(),
				age = $("input#age").val(),
				duration = $("input#duration").val(),
				revenu = $("input#revenu").val(),
				company = $("input#company").is(":checked") ? true : false,
				horaire,
				caf;
			console.log("Revenu annuel : " + revenu);

			if (
				!nb_children ||
				nb_children === "0" ||
				!duration ||
				duration === "0" ||
				!revenu
			) {
				console.log("incomplet");
			} else {
				if (company) {
					if (duration >= 1 && duration <= 29) horaire = 7.89;
					if (duration >= 30 && duration <= 39) horaire = 7.78;
					if (duration >= 40) horaire = 7.46;
				} else {
					if (duration >= 1 && duration <= 29) horaire = 9.25;
					if (duration >= 30 && duration <= 39) horaire = 8.88;
					if (duration >= 40) horaire = 8.26;
				}

				// https://www.caf.fr/index.php/allocataires/aides-et-demarches/droits-et-prestations/vie-personnelle/le-complement-de-libre-choix-du-mode-de-garde-cmg
				// if (age < 36) {
				// 	if (revenu < 22809) caf = 967.81;
				// 	if (revenu >= 22809 && revenu < 50686) caf = 834.28;
				// 	if (revenu >= 50686) caf = 700.8;
				// } else {
				// 	if (revenu < 21661) caf = 483.91;
				// 	if (revenu >= 21661 && revenu < 48135) caf = 417.15;
				// 	if (revenu >= 48135) caf = 350.4;
				// }
				// console.log("CAF : " + caf);
				
				var hebdo = (horaire * duration).toFixed(2); console.log('Hebdo brut : ' + hebdo);
				$(".price-per-week .price").html(hebdo + "€");

				//var mensuel = ((hebdo * 3.76) - caf).toFixed(2); console.log('Mensuel : ' + mensuel);
				var mensuel = (hebdo * 3.76).toFixed(2); console.log('Mensuel : ' + mensuel);
				var heures_mois = duration * 3.76;
				
				$(".price-per-hour .price").html((mensuel/heures_mois).toFixed(2) + "€");

				$(".price-per-month .price").html(mensuel + "€");

				if (company) {
					$(".couches-repas").show();
				} else {
					$(".couches-repas").hide();
				}
			}
		});
	},

	chooseOne: function () {
		$("a.choose-one").on("click", function () {
			var formData = new FormData();
			formData.append("action", "choose_calc");
			formData.append("creche_type", $(this).data("creche-type"));

			$(".choose-one").removeClass("selected");
			$(this).addClass("selected");

			$.ajax({
				url: adminAjax,
				type: "POST",
				data: formData,
				success: function (data) {
					$(".calc-container").html(data);
				},
				cache: false,
				contentType: false,
				processData: false,
			});
		});
	},
};

export { calculateur };
