const utils = {
    checkDevice : function() {
        var self = this;
        var body = document.getElementsByTagName('body')[0];

        // Supprime les class du body
        body.classList.remove(
            'isMobile', 
            'isTablet', 
            'isDesktop', 
            'isApple', 
            'isAndroid', 
            'isWindowsPhone', 
            'no-touch', 
            'has-touch', 
            'small', 
            'medium', 
            'large', 
            'xlarge', 
            'xxlarge'
        );
    
        var deviceModel = self.getDeviceModel();
        
        // Ajoute la class du Device model sur le body
        for(var i in deviceModel) {
            body.classList.add(deviceModel[i]);
        }
    
        // Device kind
        body.classList.add(self.getDeviceKind());

        // Device breakpoint
        body.classList.add(self.getBreakpoint());
    
        if(!self.isTouchDevice() && !self.hasClass(body, 'isAndroid') && !self.hasClass(body, 'isIOS')) {
            body.classList.add('no-touch');
        }else{
            body.classList.add('has-touch');
        }
    },


    // Ajoute la class du breakpoint correspondant (breakpoint par défaut de Foundation)
    getBreakpoint: function () {
        let breakpoint = 'small';

        // Si l'util MediaQuery de Foundation est importé dans main.js, on récupère le breakpoint de Foundation
        if (typeof Foundation.MediaQuery !== 'undefined') {
            Foundation.MediaQuery._init();
            breakpoint = Foundation.MediaQuery.current;
        } else {
            
            let widthDevice = this.widthDevice();            
    
            if (widthDevice > 640)    breakpoint = 'medium';
            if (widthDevice > 1024)   breakpoint = 'large';
            if (widthDevice > 1200)   breakpoint = 'xlarge';
            if (widthDevice > 1440) breakpoint = 'xxlarge';
        }

        return breakpoint;
    },


    getDeviceKind : function() {
        var widthDevice = this.widthDevice();
        var deviceKind = 'isDesktop';
    
        if (widthDevice <  640)                        deviceKind = 'isMobile';
        if (widthDevice >= 640 && widthDevice < 1024)  deviceKind = 'isTablet';
        if (widthDevice >= 1024)                       deviceKind = 'isDesktop';
    
        return deviceKind;
    },


    getDeviceModel: function () {
        const ua = navigator.userAgent;
        let deviceModel = [];
        let is_safari = /^((?!chrome|android).)*safari/i.test(ua);
    
        if (/iPad|iPhone|iPod/.test(ua) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        )                                              deviceModel.push('isIOS');
        if (/android/i.test(ua))                       deviceModel.push('isAndroid');
        if (ua.match(/iemobile/i))                     deviceModel.push('isWindowsPhone');
        if (ua.match(/BlackBerry/i))                   deviceModel.push('isBlackberry');
        if (ua.toLowerCase().indexOf('firefox') > -1)  deviceModel.push('isFirefox');
        if (window.chrome)                             deviceModel.push('isChrome');
        if (ua.match(/Android/i) && window.chrome)     deviceModel.push('isAndroid');
        if (is_safari)                                 deviceModel.push('isSafari');
    
        return deviceModel;
    },


    isTouchDevice : function() {
        if (
            ('ontouchstart' in window) || 
            (window.DocumentTouch && document instanceof DocumentTouch) ||
            ( navigator.maxTouchPoints > 0 ) ||
            ( navigator.msMaxTouchPoints > 0 )) {
                return true;
        }
    },


    widthDevice : function() {
        //return (window.innerWidth > 0) ? window.innerWidth : screen.width;
        return document.body.scrollWidth;
    },    
    

    heightDevice : function() {
        return (window.innerHeight > 0) ? window.innerHeight : screen.height;
    },    
    

    hasClass : function( target, className ) {
        return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
    },


    headerHeight : function() {
        return document.getElementById('header').offsetHeight; //$('#header').outerHeight(true);
    },
};

export {utils}