const geolocation = {
    init: function () {
        let self = this;

        $('.geolocation').on('click', function (e) {
            e.preventDefault();

            self.getLocation();
        });
    },

    getLocation: function () {
        let self = this;

        if (navigator.geolocation) {
            let position = navigator.geolocation.getCurrentPosition(self.setPosition);

            self.setPosition(position);
        } else {
            alert("La géolocalisation n'est pas supportée par votre navigateur");
        }
    },

    setPosition: function (position) {
        if ($('#searchform').length) {
            const geocoder = new google.maps.Geocoder();

            const latlng = {
                lat: parseFloat(position.coords.latitude),
                lng: parseFloat(position.coords.longitude),
            };

            // Géocode les coordonnées pour récupérer l'adresse via google geocode et l'affiche dans le champ de recherche
            geocoder
                .geocode({ location: latlng })
                .then((response) => {
                    if (response.results[0]) {
                        $('#searchform #localisation-search').val(response.results[0].formatted_address);
                    } else {
                        window.alert("No results found");
                        $('#searchform #localisation-search').val(position.coords.latitude + ',' + position.coords.longitude);
                    }
                })
                .catch((e) => window.alert("Geocoder failed due to: " + e));
        }
    }
}

if (!window.initMap) {
    window.initMap = function() {}
}

export { geolocation };