/* --- UTILISATION ---
	import {HeaderClass} from './components/_header';

	// INIT HEADER
    let header = new HeaderClass();
    header.initHeader();
*/

export default class HeaderClass {
  // constructor
  constructor(isForceScrolled = false) {
    this.windowScroll = 0;
    this.header = document.getElementById("header");
    this.isScrolled = false;
    this.isForceScrolled = isForceScrolled;
  }

  initHeader() {
    let self = this;

    if (self.header != null) {
      // Déplace le lien instagram dans le menu principal
      if ($(".header-content .instagram").length) {
        $("#menu-menu-principal").append('<li class="last"></li>');
        $(".header-content .instagram").appendTo("#menu-menu-principal .last");
      }

      self.headerScroll($(window).scrollTop());

      if (self.isForceScrolled) {
        self.header.classList.add("forced");
      }

      let $subMenuWrapper = $(".sub-menu .current-menu-item").parents(".sub-menu-wrapper");
      $subMenuWrapper.parent().addClass("current-menu-item");

      // HEADER ON SCROLL
      $(window).on("scroll", function () {
        self.headerScroll($(window).scrollTop());
      });

      // SWITCH MENU MOBILE
      let dragging = false;
      $("body")
        .on("touchmove", function () {
          dragging = true;
        })
        .on("touchstart", function () {
          dragging = false;
        });

      $("#header .navbar-toggle, #header .close-menu").on(
        "touchend, click",
        function (e) {
          if (dragging) return;

          self.switchMenu();

          e.preventDefault();
        }
      );
    }
  }

  switchMenu() {
    if (document.getElementById("header").classList.contains("open")) {
      this.closeMenu(false);
    } else {
      this.openMenu();
    }

    return false;
  }

  openMenu() {
    document.getElementById("header").classList.add("open");

    // Attend la fin des animations d'apparition du menu
    setTimeout(function () {
      document.getElementById("header").classList.add("is-open");
    }, 250);
  }

  closeMenu() {
    this.header.classList.remove("open", "is-open");
  }

  addScrolled() {
    this.header.classList.add("scrolled");
    this.isScrolled = true;
  }

  removeScrolled() {
    this.header.classList.remove("scrolled");
    this.isScrolled = false;
  }

  headerScroll(windowScroll) {
    var self = this;
    let headerHeight = self.header.offsetHeight;

    if (Math.abs(windowScroll) >= headerHeight) {
      if (!self.isScrolled) {
        self.addScrolled();
      }
    } else {
      if (self.isScrolled) {
        self.removeScrolled();
      }

      if (self.isScrolled && !self.isForceScrolled) {
        self.removeScrolled();
      }

      if (self.isForceScrolled) {
        self.addScrolled();
      }
    }
  }

  smallMenu() {
    $(".small-menu .toggle-dots").on("click", function () {
      $(this).parent().toggleClass("active");
    });
    $(window).click(function (e) {
      let $menu = $(".small-menu");

      if ($menu.hasClass("active")) {
        $menu.toggleClass("active");
      }
    });
    $(".small-menu").click(function (event) {
      event.stopPropagation();
    });
  }
}
