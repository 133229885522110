// Une partie importante du JS de cette page se trouve dans add_script_to_footer.php
// notamment tout ce qui concerne la Google Maps, ainsi que l'ajout d'un choix de crèche (car ça se passe au click d'une infoWindow de la map ...)
import Swiper, {Navigation} from 'swiper';
Swiper.use([Navigation]);
import Sortable from "sortablejs";
import datepicker from 'js-datepicker'; //https://www.npmjs.com/package/js-datepicker


const registerChildPage = {
	formComplete: false,
	debugMode: false,
	
	readyFn: function () {
		let self = this;

		//self.debugMode = true;

		// Carrousel du formulaire
		const formSlider = new Swiper(".form-slider", {
			spaceBetween: 40,
			slidesPerView: 1,
			autoHeight: true,
			allowTouchMove: false,
			on: {
				afterInit: function (swiper) {
					self.updateSteps(swiper.activeIndex);
				},

				slideChange: function (swiper) {
					// Si on arrive au résumé du formulaire, on passe à true formComplete

					if (!self.debugMode) {
						
						if (swiper.activeIndex == 4) {
							self.formComplete = true;
	
							$('.navigation .swiper-next').hide();
						} else {
							$('.navigation .swiper-next').show();
						}
	
						if (swiper.activeIndex > 0 && swiper.activeIndex < 4) {
							self.updateSteps(swiper.activeIndex);
	
							$('.swiper-slide:eq(' + swiper.activeIndex + ') input').on('input', function () {
								// Vérifie les champs Required
								self.checkRequiredInputs(swiper.activeIndex);
							});
	
							$('.swiper-slide:eq(' + swiper.activeIndex + ') select').on('change', function () {
								// Vérifie les champs Required
								self.checkRequiredInputs(swiper.activeIndex);		
							}); 
						}
					}

					let headerHeight = document.getElementById('header').offsetHeight;
					$('html, body').animate({
						scrollTop: ($('#contact-form').offset().top - headerHeight)
					}, 400);
				}
			}
		});


		// Au clic sur une le numéro d'une étape déjà validée, on swipe à cette étape
		$('#steps button').on('click', function () {
			let self = $(this);
			
			if (self.parent().hasClass('done')) {
				formSlider.slideTo(self.data('index'));

				$('#steps li').removeClass('current');
				self.parent().removeClass('done');
				self.parent().addClass('current');
			}
		});


		// Dans le slide Résumé, au clic sur un bouton Edit, on swipe au slide concerné
		$('.edit').on('click', function () {
			formSlider.slideTo($(this).data('index'));
		});


		// Swipe au slide précédent
		$('#contact-form .navigation .swiper-prev').on('click', function () {
			formSlider.slidePrev();
		});


		// Swipe au slide suivant
		$('#contact-form .navigation .swiper-next').on('click', function () {
			if (!self.debugMode) {				
				if (self.checkRequiredInputs(formSlider.activeIndex)) {
					formSlider.slideNext();
				}
			} else {
				formSlider.slideNext();
			}
		});


		// Supprimer une crèche ajoutée dans les choix
		$(document).on("click", "ul#creches-choices > li > span.remove", function () {
			$(this).closest("ul#creches-choices > li").fadeOut().remove();
			self.reorderChoices();
		});


		// Rend le choix de crèches triable
		var el = document.getElementById("creches-choices");
		Sortable.create(el, {
			filter: "span.remove",
			onSort: (evt) => {
				self.reorderChoices();
			},
		});


		// Quand on sélectionne un nombre d'enfant, ca affiche les champs à remplir pour chaque enfant
		$('#nb_child').on('change', function () {
			let nbChild = $(this).val();

			$("[class*='child-block-']").each(function () {
				let self = $(this);
				self.hide();
				self.find("input").prop("required", false);
				self.find("select:not(.sex)").prop("required", false);
			});

			for (var i = 1; i <= nbChild; i++) {
				$('.child-block-' + i).removeClass('hide').show();
	
				$('.child-block-' + i).find('input:not([id^="checkbox_noname"]):not(.qs-overlay-year)').prop('required', true);
				$('.child-block-' + i).find('select:not(.sex)').prop('required', true);
				let $thisDate = $('.child-block-' + i + ' .birthdate');
	
				if ($thisDate.parent().find('.qs-datepicker-container').length == 0) {
					let picker = datepicker($thisDate[0], {
						customDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
						customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Sept', 'Octobre', 'Novembre', 'Décembre'],
						formatter: (input, date, instance) => {
							input.value = date.toLocaleDateString();
						},
						onSelect: (instance, date) => {
							$('.swiper-slide.resume #' + $thisDate.attr('id') + '_resume').val(date.toLocaleDateString());
							
							self.checkRequiredInputs(formSlider.activeIndex);
						}
					});
				}
			}

			// Refresh la hauteur du carrousel
			formSlider.updateAutoHeight();
		});


		if ($('.birthdate').length) {			
			let $thisDate = $('.birthdate:visible');

			let picker = datepicker($thisDate[0], {
				customDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
				customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Sept', 'Octobre', 'Novembre', 'Décembre'],
				formatter: (input, date, instance) => {
					input.value = date.toLocaleDateString();
				},
				onSelect: (instance, date) => {
					$('.swiper-slide.resume #' + $thisDate.attr('id') + '_resume').val(date.toLocaleDateString());
					
					self.checkRequiredInputs(formSlider.activeIndex);
				}
			});
		}


		// Afficher / masquer le prénom de l'enfant
		$("input[id^=checkbox_noname]").on("change", function () {
			let thisCheckbox = $(this);
			let inputName = thisCheckbox.parents(".cell").find(".firstname");

			if (thisCheckbox.is(":checked")) {
				// Masque et suppr le required sur le champ prénom
				inputName
					.prop("disabled", true)
					.prop("required", false);
				inputName.hide();
			} else {
				// Affiche et rend le champ prénom obligatoires
				inputName.show();
				inputName
					.prop("disabled", false)
					.prop("required", true);
			}

			// Vérifie les champs Required
			self.checkRequiredInputs(formSlider.activeIndex);
		});


		// Quand on sélectionne un jour dans les horaires, ca rend les selects d'horaires accessibles
		$('.day-row input[type="checkbox"]').on('change', function () {
			let $thisCheckbox = $(this);
			if ($thisCheckbox.is(':checked')) {
				$thisCheckbox.parent().find('select').each(function () {
					$(this).removeAttr('disabled');
				});
			} else {
				$thisCheckbox.parent().find('select').each(function () {
					$(this).attr('disabled', '');
				});
			}
		});
		

		if ($('.DateAccueilSouhaitee__c').length) {			
			let $thisDate = $('.DateAccueilSouhaitee__c');

			let picker = datepicker($thisDate[0], {
				customDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
				customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Sept', 'Octobre', 'Novembre', 'Décembre'],
				formatter: (input, date, instance) => {
					input.value = date.toLocaleDateString();
				},
				onSelect: (instance, date) => {
					$('.swiper-slide.resume #' + $thisDate.attr('id') + '_resume').val(date.toLocaleDateString());

					self.checkRequiredInputs(formSlider.activeIndex);
				}
			});
		}


		// Afficher / masquer le block parent 2
		$("input[id^=checkbox_noparent]").on("change", function () {
			let $thisCheckbox = $(this);
			let $parentBlock = $thisCheckbox.parents(".form-block").find(".parent-block");
			let $companyBlock = $(".parent-2-company");

			if ($thisCheckbox.is(":checked")) {
				// Suppr le required sur les champs du block .parent-block
				$parentBlock.hide();
				$parentBlock
					.find('[data-required="true"]')
					.prop("disabled", true)
					.prop("required", false);
				
				$companyBlock
					.find("input:not([id^=company_additional])")
					.prop("required", false)
					.prop("disabled", true);
				
				$companyBlock
					.find("select").not('.select-job')
					.prop("required", false)
					.prop("disabled", true);
				
				$companyBlock.hide();

				$('.swiper-slide.resume .parents-wrapper').addClass('no-parent-2');
			} else {
				// Rend les champs du block .parent-block obligatoires
				$parentBlock.show();
				$parentBlock
					.find('[data-required="true"]')
					.prop("disabled", false)
					.prop("required", true);
				
				$companyBlock
					.find("input:not([id^=company_additional])")
					.prop("disabled", false)
					.prop("required", true);
				
				$companyBlock
					.find("select").not('.select-job')
					.prop("disabled", false)
					.prop("required", true);
				
				$companyBlock.show();

				//$('.swiper-slide.resume .parent-2').show();
				$('.swiper-slide.resume .parents-wrapper').removeClass('no-parent-2');
			}

			// Vérifie les champs Required
			self.checkRequiredInputs(formSlider.activeIndex);

			// Refresh la hauteur du carrousel
			formSlider.updateAutoHeight();
		});


		// Afficher / masquer l'adresse postale du parent 2
		$("#checkbox_same_address").on("change", function (e) {
			let thisCheckbox = $(this);
			let parentAddress = $(".address-parent-2");
			let address1Empty = false

			// On vérifie si l'adresse du parent 1 est remplie
			$('.address-parent-1 input').each(function () {
				if ($(this).val() == '') {
					address1Empty = true;
				}
			});


			if (thisCheckbox.is(":checked")) {

				// Si l'adresse du parent 1 n'est pas vide
				if (!address1Empty) {

					// Suppr le required sur les champs du block addresse
					parentAddress.hide();
					parentAddress
						.find('[data-required="true"]')
						.prop("disabled", true)
						.prop("required", false);
					
				} else { // Sinon affiche un message et décoche la checkbox
					alert("L'adresse du parent 1 est incomplète");
					thisCheckbox.prop( "checked", false );
				}
			} else {
				// Rend les champs du block addresse obligatoires
				parentAddress.show();
				parentAddress
					.find('[data-required="true"]')
					.prop("disabled", false)
					.prop("required", true);
			}

			// Vérifie les champs Required
			self.checkRequiredInputs(formSlider.activeIndex);

			// Refresh la hauteur du carrousel
			formSlider.updateAutoHeight();
		});


		// Suivant le nombre d'enfants total sélectionné, on affiche un select de revenus différent
		$('#total_nb_child').on('change', function () {
			let totalNbChild = $(this).val();

			$('.earning-select').each(function () {
				$(this).prop('required', false).hide();
			})

			$('[id^="earning_child' + totalNbChild + '"]').show();
			$('[id^="earning_child' + totalNbChild + '"]').prop('required', true);
		});


		// Afficher / masquer le block entreprise des parents 1 et 2
		$(".select-job").on("change", function () {
			let $thisSelect = $(this);
			let $companyBlock = $thisSelect.parents(".form-block").find(".company-block");

			if ($thisSelect.val() == 'Sans emploi / En reconversion') {
				// Suppr le required sur les champs du block Entreprise qu'on masque
				$companyBlock
					.find("input:not([id^=company_additional])")
					.prop("required", false)
					.prop("disabled", true);
				
				$companyBlock
					.find("select").not($thisSelect)
					.prop("required", false)
					.prop("disabled", true);
				
				$companyBlock.find('.cell:not(.select-job-wrapper)').fadeOut('fast');
				
			} else {
				// Rend les champs du block Entreprise affiché obligatoires (sauf complément d'adresse)
				$companyBlock
					.find("input:not([id^=company_additional])")
					.prop("disabled", false)
					.prop("required", true);
				
				$companyBlock
					.find("select")
					.prop("disabled", false)
					.prop("required", true);
				
				$companyBlock.find('.cell').fadeIn('fast');
			}

			// Vérifie les champs Required
			self.checkRequiredInputs(formSlider.activeIndex);

			// Refresh la hauteur du carrousel
			formSlider.updateAutoHeight();
		});


		// Lorsqu'on édite un input dans les slide, ca met à jour le même input dans le slide Résumé
		$('.swiper-slide:not(.resume) input').on('input', function () {
			let $thisInput = $(this);
			let thisId = $thisInput.attr('id');
			let thisResumeInput = $('.swiper-slide.resume #' + thisId + '_resume');

			if (!$thisInput.hasClass('qs-overlay-year')) {
				
				if ($thisInput.attr('type') != 'checkbox' && $thisInput.attr('type') != 'radio') {
					thisResumeInput.val($thisInput.val());
				} else {
					if ($thisInput.is(':checked')) {
						thisResumeInput.prop('checked', true);
					} else {
						thisResumeInput.prop('checked', false);
					}
				}
			}
		});

		// Lorsqu'on édite un select dans les slide, ca met à jour le même input dans le slide Résumé
		$('.swiper-slide:not(.resume) select').on('change', function () {
			let $thisInput = $(this);
			let thisId = $thisInput.attr('id');

			let thisResumeInput = $('.swiper-slide.resume #' + thisId + '_resume');

			thisResumeInput.val($thisInput.val()).trigger('change');
		});

		// Au chargement de la page, trigger change sur les select pour mettre à jour les select dans le slide Résume (pour les select d'horaires par exemple)
		$('.swiper-slide:not(.resume) .schedules select').each(function () {
			$(this).trigger('change');
		});
	},

	// Met à jour les Steps suivant le slide dans lequel on est
	updateSteps: function (activeIndex) {
		let self = this;

		$('#steps li').removeClass('current done');
		
		if (!self.formComplete) {
			for (var i = 0; i < activeIndex; i++) {
				$('#steps li:eq(' + i + ')').removeClass('current').addClass('done');
			}
		} else {
			$('#steps li').addClass('done');
		}

		$('#steps li:eq(' + activeIndex + ')').addClass('current');
	},

	// Réordonner les choix
	reorderChoices: function () {
		let field_ids = ["ChoixCreche1Formulaire__c", "ChoixCreche2Formulaire__c", "ChoixCreche3Formulaire__c"];
		$("ul#creches-choices > li").each(function (index) {
			let id = field_ids[index];
			$(this).find("input").attr("name", id);
		});
	},

	// Vérifie les input required du slide current pour afficher ou non le bouton Suivant
	checkRequiredInputs: function (activeIndex) {
		let areFilled = [];		

		$('.swiper-slide:eq(' + activeIndex + ') label').removeClass('error');
		$('.swiper-slide:eq(' + activeIndex + ') input').removeClass('error');
		$('.swiper-slide:eq(' + activeIndex + ') select').removeClass('error');

		$('.swiper-slide:eq(' + activeIndex + '):not(.resume) [required]').each(function () {
			let $thisInput = $(this);
			let thisId = $thisInput.attr('id');
			let isFilled = true;
			
			if (!$thisInput.hasClass('qs-overlay-year')) {
				switch ($thisInput.attr('type')) {
					case 'checkbox':
						if (!$thisInput.is(':checked')) {
							areFilled.push(false);
							isFilled = false;
						}
						
						break;
					case 'radio':
						let radioName = $thisInput.attr('name');
						if (!$('input[name="' + radioName + '"]').is(':checked')) {
							areFilled.push(false);
							isFilled = false;
						}
						break;
					default:
						console.log('$thisInput.val()', $thisInput.val());
						
						if ($thisInput.val() == '' || $thisInput.val() == null) {
							$thisInput.addClass('error');
							areFilled.push(false);
							isFilled = false;
						}
						break;
				}

				console.log('$thisInput : ', $thisInput, '  isFilled : ', isFilled);

				if (!isFilled) {
					$('label[for="' + thisId + '"]').addClass('error');
				} else {
					$('label[for="' + thisId + '"]').removeClass('error');
				}
			}
		});
		
		if (areFilled.includes(false)) {
			return false;
		} else {
			return true;
		}
	}
};

export { registerChildPage };