import { Accordion } from 'foundation-sites/js/foundation.accordion';
Foundation.plugin(Accordion, 'Accordion');

const faq = {
    init : function () {
        if ($('#faq .accordion-item').length) {
            var elem = new Foundation.Accordion($('#faq .accordion'));
        }        
    }
}

export { faq };